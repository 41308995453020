<template>
  <div
    v-if="massEmail"
    :style="{
      pointerEvents: isStoring ? 'none' : 'auto',
    }"
  >
    <b-breadcrumb
      class="mb-1"
      :items="[
        {
          text: 'Ferramentas',
          active: true,
        },
        {
          text: 'Emails',
          active: true,
        },
        {
          text: 'Envio em massa',
          to: { name: 'cms-mass-emails' },
        },
        {
          text: 'Edição',
          active: true,
        },
      ]"
    />
    <b-card>
      <b-card-title> Envio em massa </b-card-title>
      <b-card-body>
        <validation-observer ref="rules">
          <b-row>
            <b-col>
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  label="Assunto"
                  label-for="subject"
                >
                  <b-form-input
                    id="subject"
                    v-model="subject"
                    :disabled="!isEditable"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  label="HTML"
                  label-for="body"
                >
                  <b-form-textarea
                    id="body"
                    v-model="body"
                    rows="8"
                    :disabled="!isEditable"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <b-form-group
                label="IDs dos destinatários (um por linha)"
                label-for="to"
              >
                <b-form-textarea
                  id="to"
                  v-model="to"
                  rows="5"
                  :disabled="!isEditable"
                />
              </b-form-group>
              <b-row>
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group
                      label="Tipo de desconto"
                      label-for="discount_type"
                    >
                      <b-form-select
                        id="discount_type"
                        v-model="discount_type"
                        :options="massEmailDiscountTypeOptions"
                        :disabled="!isEditable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider>
                    <b-form-group
                      label="Vencimento do desconto"
                      label-for="discount_expires_at"
                    >
                      <b-input-group>
                        <cleave
                          id="discount_expires_at"
                          v-model="discount_expires_at"
                          type="text"
                          class="form-control"
                          placeholder="DD/MM/YYYY"
                          autocomplete="off"
                          :raw="false"
                          show-decade-nav
                          :options="maskDate"
                          :disabled="!isEditable"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="discount_expires_at_dp"
                            show-decade-nav
                            button-only
                            button-variant="outline-primary"
                            right
                            size="sm"
                            :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                            locale="pt-BR"
                            :disabled="!isEditable"
                            @context="(ctx) => { discount_expires_at = (ctx.selectedDate) ? ctx.selectedFormatted : '' }"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    :rules="{
                      required: true,
                      regex: /^\d+(?:\,\d+)?$/
                    }"
                  >
                    <b-form-group
                      label="Desconto"
                      label-for="discount"
                    >
                      <b-input-group>
                        <b-input-group-prepend
                          v-if="discount_type === 'fixed'"
                          is-text
                        >
                          R$
                        </b-input-group-prepend>

                        <b-form-input
                          id="discount"
                          v-model="discount"
                          :disabled="!isEditable"
                        />

                        <b-input-group-append
                          v-if="discount_type === 'percentage'"
                          is-text
                        >
                          %
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group
                      label="Status"
                      label-for="status"
                    >
                      <b-form-select
                        id="status"
                        v-model="status"
                        :options="massEmailStatusOptions"
                        :disabled="!isEditable"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <div
            v-if="isEditable"
            class="text-right"
          >
            <b-button
              variant="gradient-primary"
              @click="store()"
            >
              Salvar
              <b-spinner
                v-if="isStoring"
                small
              />
            </b-button>
          </div>
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormTextarea,
  BFormSelect,
  BBreadcrumb,
  BSpinner,
  BButton,
  BCardTitle,
  BCardBody,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { maskDate } from '@/utils/masks'
import {
  massEmailStatusOptions,
  massEmailDiscountTypeOptions,
} from '@/utils/options'
import MassEmailService from '@/services/massEmailService'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { formatDateDbToView, formatDateViewToDb } from '@/utils/helpers'

export default {
  name: 'MassEmailEdit',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,
    BFormSelect,
    BBreadcrumb,
    BSpinner,
    BButton,
    BCardTitle,
    BCardBody,
    ValidationObserver,
    ValidationProvider,
    Cleave,
  },
  props: {
    massEmail: {
      type: Object,
      default: null,
    },
  },
  data() {
    if (!this.massEmail) {
      return {}
    }

    const fields = [
      'id',
      'subject',
      'body',
      'to',
      'subject',
      'body',
      'status',
      'discount',
      'discount_type',
      'discount_expires_at',
    ]

    const massEmailFields = Object.fromEntries(
      fields.reduce((carry, field) => {
        if (field === 'status' && !this.massEmail[field]) {
          return [
            ...carry,
            [field, 'editing'],
          ]
        }

        if (field === 'to' && this.massEmail[field]) {
          return [
            ...carry,
            [field, this.massEmail[field].join('\n')],
          ]
        }

        if (field === 'discount_type' && !this.massEmail[field]) {
          return [
            ...carry,
            [field, massEmailDiscountTypeOptions[0].value],
          ]
        }

        if (field === 'discount_expires_at') {
          return [
            ...carry,
            [field, formatDateDbToView(this.massEmail[field])],
            [`${field}_dp`, formatDateViewToDb(formatDateDbToView(this.massEmail[field]))],
          ]
        }

        if (field === 'discount' && this.massEmail[field]) {
          return [
            ...carry,
            [field, String(this.massEmail[field]).replace('.', ',')],
          ]
        }

        return [
          ...carry,
          [field, this.massEmail[field]],
        ]
      }, []),
    )

    return {
      isStoring: false,
      massEmailDiscountTypeOptions,
      maskDate,
      fields,
      discount_expires_at_dp: '',
      ...massEmailFields,
    }
  },
  computed: {
    massEmailStatusOptions() {
      return massEmailStatusOptions.reduce((carry, option) => {
        if (!this.isEditable || (
          this.isEditable && ['editing', 'awaiting'].includes(option.value)
        )) {
          return [
            ...carry,
            option,
          ]
        }

        return carry
      }, [])
    },
    isEditable() {
      return ['editing', 'awaiting'].includes(this.status)
    },
    massEmailForm() {
      const formData = new FormData()
      this.fields.forEach(field => {
        if (field === 'to') {
          const userIds = (this[field] || '')
            .split('\n')
            .map(m => m.trim())
            .filter(f => parseInt(f, 10))

          userIds.forEach(userId => {
            formData.append(`${field}[]`, userId)
          })
          return
        }

        if (field === 'discount_expires_at') {
          formData.append(field, formatDateViewToDb(this[field]))
          return
        }

        if (field === 'discount') {
          formData.append(field, this[field].replace(',', '.'))
          return
        }

        formData.append(field, this[field])
      })

      return formData
    },
  },
  watch: {
    massEmail: {
      handler(massEmail) {
        if (!massEmail) {
          this.$router.push({ name: 'cms-mass-emails' })
        }
      },
      immediate: true,
    },
  },
  methods: {
    async store() {
      const validated = await this.$refs.rules.validate()
      if (!validated) {
        return
      }

      this.isStoring = true

      try {
        await MassEmailService.store(this.massEmailForm)
        this.$router.push({ name: 'cms-mass-emails' })
      } catch (error) {
        this.$swal({
          title: 'Erro ao salvar',
          text: error.response ? Object.values(error.response.data.errors) : error.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } finally {
        this.isStoring = false
      }
    },
  },
}
</script>
